
html, body {
  height:100%;
  width: 100%;
  padding:0;
  margin:0;
  font-family: 'Libre Baskerville', serif !important;
}


  #root {
    height:100%;
    width: 100%;
    padding:0;
    margin:0;
    font-family: 'Libre Baskerville', serif !important;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .column-splash {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:360pt;
  }

  .login-splash {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top:150pt; */
  }

  .registration-splash {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top:240pt; */
  }

  .column-credits {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .bold {
    font-weight: bold !important;
  }

  .contact-us {
    width: 100%;
    padding: 1rem;
    margin: 2rem;
    border: none;
  }


  body {
    background-color:#EAFDFD;
  }

  /* @media only screen and (max-height: 1000px) {
    body {
      background-color: pink;
    }
  } */



  @media only screen and (max-height: 1000px) {
    .column-space-between {
      width:100%;
      margin: 0 auto;
      max-width: 400px;
      font-size: 0.95rem !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    /*.second-body {*/
    /*  background: #C6E1F9;*/
    /*  width: 100%;*/
    /*  height:100%;*/
    /*  display: flex;*/
    /*}*/
  }

  @media only screen and (min-height: 1000px) {
    .column-space-between {
      width:100%;
      height:100%; 
      margin: 0 auto;
      max-width: 400px;
      font-size: 0.95rem !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    /*.second-body {*/
    /*  background: #C6E1F9;*/
    /*  width: 100%;*/
    /*  height: 100%;*/
    /*  display: flex;*/
    }

  .second-body {
    background: #C6E1F9;
    width: 100%;
    height: 100%;
    display: flex;
  }


  .column-intro {
    width:100%;
    margin: 0 auto;
    max-width: 400px;
    font-size: 0.95rem !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }


  .title {
    color: #fff;
  }

  .input_gdpr {
    background: transparent;
    border: none;
    font-family: Libre Baskerville;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    margin-top: 100pt;
    margin-block-end: 10pt;
  }

  .register {
    width: 203px;
    height: 16px;
    left: 106px;
    top: 632px;
    font-family: Libre Baskerville;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
  }

  .tutorial_item_login {
    width: 313px;
    height: 80px;
    left: 51px;
    top: 122px;
    font-family: Libre Baskerville;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #482C2A;
  }

  .tutorial_item_login_2 {
    width: 333px;
    height: 22px;
    left: 41px;
    top: 229px;
    font-family: Libre Baskerville;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #482C2A;
  }

  .tutorial_item_login_3 {
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    line-height: 2.8rem;
    padding: 3rem 0;
  }

  .title_img {
    width: 333px;
    height: 22px;
    left: 41px;
    top: 229px;
    font-family: Libre Baskerville;
    font-style: normal;
    font-weight: bold !important;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #482C2A;
  }
  
  input::placeholder {
    color: #fff;
    font-size: 1.2em;
    font-style: normal;
    font-family: Libre Baskerville;
  }


  .powered {
    color: #fff;
    font-size: 15px;
    font-family: 'Libre Baskerville' !important;
    font-weight: bold;
    margin-top: 250pt;
    margin-block-end: 10pt;
  }
  .power {
    color: black;
    font-size: 15px;
    font-family: 'Libre Baskerville' !important;
    font-weight: bold;
    margin-top: 50pt;
    margin-block-end: 10pt;
  }

  .lang_selector {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
  }

  .right {
    margin-left: auto;
    margin-right: 1rem;
    color: #482C2A
  }

  .app_bar {
    padding: 0 1rem;
    font-family: 'Libre Baskerville', serif !important;
    font-size: 1.0rem;
    font-weight: bold !important;
  }


  .tutorial_item {
    font-weight: 400;
    font-size: 1.7rem;
    text-align: center;
    line-height: 2.8rem;
    padding: 3rem 0;
  }



  .description {
    padding: 0 1rem;
    line-height: 1.8rem;
    text-align: justify;
  }

  .box {
    border-radius: 25px;
    border: 2px solid #fff;
    background-color: transparent;
    padding: 2opx;
    width: 333px;
    height: 52px;
    left: 40px;
    top: 373px;  
    text-indent: 1rem;
    font-family: Libre Baskerville !important;
    font-style: normal;
    font-size: 15px !important;
    line-height: 21px;
    letter-spacing: 0.242857px;
    color: #fff;
    
  }
  
  .input_primary_signup{

    margin-top: 4rem;
    background: transparent;
    border: none;
    width: 285px;
    height: 48px !important;
    font-family: Libre Baskerville !important;
    font-style: normal;
    font-weight: bold !important;
    font-size: 17px !important;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.242857px;
    color: #000000;
    background: #A3C7E8;
    border-radius: 25px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  }
  
  .input_primary {
    margin-top: 4rem;
    background: transparent;
    border: none;
    width: 15rem;
    height: 2.5rem !important;
    font-family: Libre Baskerville !important;
    font-style: normal;
    font-weight: bold !important;
    font-size: 17px !important;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.242857px;
    color: #000000;
    background: #A3C7E8;
    border-radius: 24px;
  }

  .input_flat {
    margin: 3rem auto;
    background: transparent;
    border: none;
    font-family: Libre Baskerville;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
  }

  .input_flat_credential{
    margin: 3rem auto;
    background: transparent;
    color: #fff;
    border: none;
    font-family: Libre Baskerville;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
  }

  .credit-item {
    text-align: center;
    line-height: 1.8rem;
  }

  .margin-top {
    margin-top: 4em !important;
  }

  .margin-top-2 {
    margin-top: 2em !important;
  }

  .margin-top-3 {
    margin-top: 3.4em !important;
  }

  .margin-bottom-4 {
    margin-bottom: 4em !important;
  }

  .margin-top-4 {
    margin-top: 4em
  }

  .margin-top-8 {
    margin-top: 18em
  }



  img{
    
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .img-container {
    min-height: 200pt;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
  }

  .smile-button {
    background-color: transparent;
    border-color: transparent;
  }

  .row::after {

    content: "";
    clear: both;
    display: table;
    padding: 2rem;
  }
  

  .smile-img {
    width: 53px;
    height: 53px;
    left: 115px;
    top: 345px;
    font-family: Libre Baskerville;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    color: #482C2A;
    
  }

  .smile-container {
    width: 250px;
    height: 280px;
    display: flex !important;
    margin: auto !important;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
  }

  .container {
    width: 250px;
    height: 280px;
    display:flex;
    margin: auto;
    flex-direction: column;
    justify-content:space-between;
    align-items:center;
  }

  .circle-container {
      height: 100%;
      width: 100%;
      display:flex;
      justify-content:center;
      align-items:center;
  }

  input:focus, textarea:focus, button:focus {
    outline: none !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
    transition: background-color 5000s;
    -webkit-text-fill-color: #fff !important;
  }

  
  .playing {
    width: 200px;
    height: 200px;
    background-color: #A3C7E8;
    animation-name: pulse;
    border-radius: 250px;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    display:flex;
    justify-content:center;
    align-items:center;
    color: #000
  }

  .init {
    width: 150px;
    height: 150px;
    background-color: #A3C7E8;
    border-radius: 200px;
    display:flex;
    justify-content:center;
    align-items:center;
    color: #000
  }

  .done {
    width: 150px;
    height: 150px;
    background-color: #aebfb0;
    border-radius: 200px;
    display:flex;
    justify-content:center;
    align-items:center;
    color: #333
  }

  .splash {
    
    background: rgb(238,174,202);
    background: linear-gradient(180deg, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
    width: 0 auto;
    height: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  }

.margin-bottom-2 {
  margin-bottom: 2rem !important;
}

  
  @keyframes pulse {
    0% {background-color: #A3C7E8; width: 150px; height: 150px; color: #000;}
    50% {background-color: #EAFDFD; width: 200px; height: 200px; color: #222; }
    100% {background-color: #A3C7E8; width: 150px; height: 150px; color: #000; }
  }